<template>
  <v-app>
    <v-row align="center">
      <v-spacer />
      <v-col align="center">
        <v-img :src="logoUrl" max-width="240" max-height="240" align="center" />
      </v-col>
      <v-col cols="4" align="center">
        <v-card flat max-width="350">
          <v-card-title class="text-h2 mb-8">nobunaga</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-text-field
                  prepend-icon="mdi-account-circle"
                  v-model="credentials.userId"
                  label="登録メールアドレス"
                  :rules="[rules.userId]"
                  required
                />

                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  v-model="credentials.password"
                  label="パスワード"
                  :rules="[rules.password]"
                  required
                />
              </v-container>
            </v-form>

            <v-spacer />
            <v-row justify="center">
              <v-col>
                <v-btn
                  :disabled="!valid"
                  x-large
                  block
                  color="primary"
                  @click="login"
                  >ログイン</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-btn text to="reset" class="mt-4">
          <span class="text-decoration-underline"
            >パスワード再発行はこちら</span
          >
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- snack -->
    <v-snackbar v-model="snack" :timeout="snackTimeout" :color="snackColor">
      {{ snackText }}
    </v-snackbar>

    <v-overlay v-show="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    logoUrl: require("@/assets/logo_nobunaga.png"),
    loginStatus: "",
    loading: false,
    showPassword: false,
    valid: true,
    credentials: {},
    rules: {
      userId: (v) => !!v || "必須入力です",
      password: (v) => !!v || "必須入力です",
    },

    // snack
    snack: false,
    snackTimeout: -1,
    snackColor: "",
    snackText: "",
  }),
  async mounted() {},
  computed: {
    ...mapGetters(["progress"]),
  },
  methods: {
    ...mapActions(["loginToFirebase", "isVerifiedPhoneNumber"]),
    async login() {
      // 入力チェック
      const submit = this.$refs.form.validate();
      if (!submit) {
        return;
      }

      // 画面入力値
      const email = this.credentials.userId;
      const password = this.credentials.password;

      // ログイン
      const loginResult = await this.loginToFirebase({ email, password });
      if (loginResult) {
        await this.loginOkProcess();
      } else {
        this.loginNgProcess();
      }
    },
    async loginOkProcess() {
      // SMS 認証済みかどうか判定
      // - 未 -> SMS 認証画面
      // - 済 -> TOP 画面
      const phoneNumber = await this.isVerifiedPhoneNumber();
      console.log("loginOkProcess", phoneNumber);
      if (phoneNumber) {
        let redirectUrl = this.$route.query.redirect;
        if (!redirectUrl) {
          redirectUrl = "/";
        }
        this.$router.replace(redirectUrl);
      } else {
        this.$router.replace("/auth/phone");
      }
    },
    loginNgProcess() {
      // エラー表示
      this.credentials.password = null; // クリア (連打防止)
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "ログインに失敗しました。もう一度お願いします。";
    },
  },
};
</script>
